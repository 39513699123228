import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';

import { ImageEntry } from '@/lib/parsers/images';
import { Sprinkles } from '@/theme/sprinkles.css';
import NextImage, { ImageProps as NextImageProps } from 'next/image';
import Shim, { ShimProps } from '../Shim';
import * as styles from './Image.css';
// import { ResponsiveImageTransform } from './useImageTransform';

export type ImageProps<T extends TagName = 'img'> = PropsOverride<
  NextImageProps,
  Partial<ImageEntry> & {
    ShimProps?: ShimProps<T>;
    ratio?: Sprinkles['ratio'];
    rounded?: boolean;
    caption?: string | null;
    // transform?: ResponsiveImageTransform;
  } & styles.ImageVariants
>;

const Image = <T extends TagName>(props: ImageProps<T>) => {
  const {
    ratio,
    ShimProps: maybeShimProps,
    fixedRatio: maybeFixedRatio,
    title,
    contain,
    rounded,
    round,
    mimeType: _mimeType,
    noCrop: _noCrop,
    // transform,
    transforms: _transforms,
    src,
    ...rest
  } = props;

  // TODO: Re-enable this when figured out how to use in combination with the public folder
  // const { url: src, ...responsiveValues } = useImageTransform(props, transform) ?? {};

  /**
   * Create the Shim
   */
  const ShimProps: ShimProps<T> | undefined =
    maybeShimProps || ratio || rounded || round
      ? (mergePropsClassName(
          { ratio, round, rounded, ...maybeShimProps },
          styles.shim
        ) as ShimProps<T>)
      : undefined;

  const fixedRatio = maybeFixedRatio ?? !!ShimProps;

  const img = !src ? (
    <></>
  ) : (
    <NextImage
      alt=""
      {...{
        'aria-label': title,
        ...mergePropsClassName(
          rest,
          styles.root({
            fixedRatio,
            contain,
          })
        ),
        src,
        // ...responsiveValues,
      }}
    />
  );

  if (ShimProps) return <Shim {...ShimProps}>{img}</Shim>;

  return img;
};

Image.styles = styles;

export default Image;
