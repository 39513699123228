'use client';

import { PageEntryTypes } from '@/lib/parsers/entries';
import { useView } from '@/lib/store/hooks';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import Box, { BoxProps } from '../ui/Box';
import PageFeatureImage from './PageFeatureImage';
import PageVideo from './PageVideo';

export type PageMedia = BoxProps<'div'>;

export default function PageMedia(props: PageMedia) {
  const page = useView() as PageEntryTypes;
  const videoUrl = maybeGet(page, 'videoUrl') ?? null;

  return <Box {...props}>{videoUrl ? <PageVideo /> : <PageFeatureImage />}</Box>;
}
