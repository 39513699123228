'use client';

import { Entry } from '@/lib/parsers/entries';
import { useView } from '@/lib/store/hooks';
import VideoBackground from '../Video/VideoBackground';

import TxtHighlight from '../TxtHighlight';
import * as styles from './PageHeader.css';
import PageHeaderRoot from './PageHeaderRoot';
import Box from '../ui/Box';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { useNavbarState } from '../NavBar/useNavbarState';
import Btn from '../ui/Btn';
import Flex from '../ui/Flex';
import { useState } from 'react';
import Image from '../Image';

const PageHeaderHome = () => {
  const page = useView() as Entry<'home', 'home'>;
  const { heading, videoUrl, entryImage } = page ?? {};
  const { height, fallbackHeight } = useNavbarState();
  const [playing, setPlaying] = useState<boolean>(true);
  const [muted, setMuted] = useState<boolean>(true);

  return (
    <PageHeaderRoot
      variant="home"
      afterContainer={
        <Box
          className={styles.hero}
          style={assignInlineVars({ [styles.navbarHeight]: `${height ?? fallbackHeight}px` })}>
          {videoUrl ? (
            <Box>
              <Flex
                direction="row"
                columnGap="3xs"
                cx={{ position: 'absolute', m: 'xs' }}
                style={{ zIndex: 100, right: 0 }}>
                <Btn
                  aria-label={playing ? 'pause' : 'play'}
                  variant="icon"
                  color="accent"
                  size="tiny"
                  StartIconProps={{ name: playing ? 'pause' : 'play', cx: { fontSize: 'h4' } }}
                  onClick={() => setPlaying(!playing)}
                />
                <Btn
                  aria-label={muted ? 'unmute' : 'mute'}
                  variant="icon"
                  color="accent"
                  size="tiny"
                  StartIconProps={{ name: muted ? 'unmute' : 'mute', cx: { fontSize: 'h4' } }}
                  onClick={() => setMuted(!muted)}
                />
              </Flex>
              <VideoBackground
                className={styles.heroVideo}
                videoUrl={videoUrl}
                playing={playing}
                muted={muted}
              />
            </Box>
          ) : (
            <Image alt="hero-image" fixedRatio {...entryImage} />
          )}
          <TxtHighlight className={styles.heroText} variant="headline" as="h1">
            {heading}
          </TxtHighlight>
        </Box>
      }
    />
  );
};

export default PageHeaderHome;
